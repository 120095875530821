//Breakpoints

$grid-max: 2000px;
$grid-content: 1440px;
$grid-desktop: 1280px;
$grid-tablet-l: 1024px;
$grid-tablet-m: 850px;
$grid-tablet-p: 768px;
$grid-phone: 375px;

//Viewport Mixin
/*
  use case
  @include viewport(tablet_l){

  }
*/

@mixin viewport($media) {
	// Starts @ 375px
	@if $media == phone {
		@media screen and (min-width: $grid-phone) {
			@content;
		}
	}
	// Starts @ 768px
	@if $media == tablet_p {
		@media screen and (min-width: $grid-tablet-p) {
			@content;
		}
	}
	// Starts @ 850px
	@if $media == tablet_m {
		@media screen and (min-width: $grid-tablet-m) {
			@content;
		}
	}
	// Starts @ 1024px
	@if $media == tablet_l {
		@media screen and (min-width: $grid-tablet-l) {
			@content;
		}
	}
	// Starts @ 1280px
	@if $media == desktop {
		@media screen and (min-width: $grid-desktop) {
			@content;
		}
	}
	// Starts @ 1440px
	@if $media == max_content {
		@media screen and (min-width: $grid-content) {
			@content;
		}
	}
}
