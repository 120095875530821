//Mixins

@mixin animate($time, $type: 'all'){
  -webkit-transition: $type $time ease-out;
  -moz-transition: $type $time ease-out;
  -ms-transition: $type $time ease-out;
  -o-transition: $type $time ease-out;
  transition: $type $time ease-out;
}

@mixin rotate($deg){
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin border_radius($size){
  -webkit-border-radius: $size;
  -ms-border-radius: $size;
  border-radius: $size;
}

@mixin vertical_center(){
  position: absolute;
  top:50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin horizontal_center(){
  position: absolute;
  left:50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@mixin full_center(){
  position: absolute;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin clear_transform(){
  top:auto;
  left:auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform:none;
}

@mixin full_float(){
  clear: both;
  float: left;
  width: 100%;
  max-width: 100%;
}

@mixin clear(){
  content: '';
  display: table;
  clear: both;
}

@mixin no_float($max){
  float: none;
  clear: both;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $max;

  &:after{
    @include clear();
  }
}

@mixin flex_evenly(){
  justify-content: space-evenly;

  @supports (-ms-ime-align: auto){ /* Edge only */
    justify-content: space-around;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { /* IE only */
    justify-content: space-between;
  }
}

@mixin clear_flex(){
  display: block;
  -webkit-align-items: none;
  -ms-align-items: none;
  align-items: none;
  -webkit-justify-content: none;
  -ms-justify-content: none;
  justify-content: none;
  flex: none;
}
