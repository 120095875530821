//Footer Container

footer{
  @include grid_wrapper;
  display: grid;
  padding-top: 20px;
  padding-bottom: 20px;
	font-size: 0.6em;
	color: $background-color;
	background: $dark-color;
  text-align: center;
  align-items: center;
  grid-template-columns: 1fr;

  > div{
    padding: 5px;
  }

  a{
		font-size: 1.5em;
		color: $background-color;
		margin-right: 20px;

    &:last-of-type{
  		margin-right: 0px;
  	}
	}

  @include viewport(phone){
    grid-template-columns: auto auto;
    justify-content: space-between;
    text-align: right;
  }
}
