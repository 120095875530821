
.services{

  .block{
    @include grid_wrapper;
    padding-top: 20px;
    padding-bottom: 20px;

    &:nth-child(even){
      text-align: right;
      background: $dark-opac-light;

  		> h1{
  			text-align: right;
  		}

  		> div{
  			float: right;
  		}
    }

    .logos{
      > a{
        vertical-align: top;
    		display: inline-block;
    		max-width: 50px;
    		float: left;
    		margin: 20px 20px 20px 0;
        opacity: $logo-opacity;

    		&:hover{
    			opacity: 1;
    		}

        > img{
          @include full_float;
        }
      }
    }

    @include viewport(tablet_l){
      > div{
        width: 50%;
        padding-left: 2%;
      }

      &:nth-child(even) > div{
        padding-right: 2%;
      }
    }
  }

}
