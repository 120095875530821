// Test Grid Lines for placement / testing

.grid_lines{
  @include grid_wrapper;
  @include horizontal_center;
  position: fixed;
  top: 0px;
  margin: 0;
  z-index: 2000;

  > div{
    @include grid_container();
    grid-template-columns: repeat(12, [col-start] 1fr);

    > div{
      padding: 2px 0;
      background: red;
    }
  }
}

.break_info{
  @include grid_wrapper;
  @include horizontal_center;
  position: fixed;
  bottom: 0px;
  margin: 0;
  z-index: 2000;
  background: red;
  color: white;
  padding: 10px;
  border-top: 1px solid black;

  &:after{
    content: 'Breakpoint: Default';

    @include viewport(phone){
      content: 'Breakpoint: Phone / phone';
    }

    @include viewport(tablet_p){
      content: 'Breakpoint: Tablet Portrait / tablet_p';
    }

    @include viewport(tablet_l){
      content: 'Breakpoint: Tablet Landscape / tablet_l';
    }

    @include viewport(desktop){
      content: 'Breakpoint: Desktop / desktop';
    }

    @include viewport(max_content){
      content: 'Breakpoint: Max Content / max_content';
    }
  }
}
