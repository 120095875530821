//Grid Variables
$grid-gap-desktop: 24px;
$grid-gap-mobile: 12px;

$grid-padding-max: 20px;
$grid-padding-desktop: 20px;
$grid-padding-tablet-l: 20px;
$grid-padding-tablet-p: 20px;
$grid-padding-phone: 20px;

$col-1: percentage(1/12);
$col-2: percentage(2/12);
$col-3: percentage(3/12);
$col-4: percentage(4/12);
$col-5: percentage(5/12);
$col-6: percentage(6/12);
$col-7: percentage(7/12);
$col-8: percentage(8/12);
$col-9: percentage(9/12);
$col-10: percentage(10/12);
$col-11: percentage(11/12);
$col-12: percentage(12/12);

$max-col-container: calc(#{$grid-max} - (#{$grid-padding-desktop} * 2));
$max-single: calc(#{$max-col-container} / 12);
$max-col-1: calc(#{$max-single} - #{$grid-gap-desktop});
$max-col-2: calc(#{$max-single} * 2 - #{$grid-gap-desktop});
$max-col-3: calc(#{$max-single} * 3 - #{$grid-gap-desktop});
$max-col-4: calc(#{$max-single} * 4 - #{$grid-gap-desktop});
$max-col-5: calc(#{$max-single} * 5 - #{$grid-gap-desktop});
$max-col-6: calc(#{$max-single} * 6 - #{$grid-gap-desktop});
$max-col-7: calc(#{$max-single} * 7 - #{$grid-gap-desktop});
$max-col-8: calc(#{$max-single} * 8 - #{$grid-gap-desktop});
$max-col-9: calc(#{$max-single} * 9 - #{$grid-gap-desktop});
$max-col-10: calc(#{$max-single} * 10 - #{$grid-gap-desktop});
$max-col-11: calc(#{$max-single} * 11 - #{$grid-gap-desktop});
$max-col-12: calc(#{$max-single} * 12);

//Grid Layout / Wrappers

@mixin grid_wrapper($max: $grid-max, $margin: false){
  @include no_float($max);
	padding-left: $grid-padding-phone;
	padding-right: $grid-padding-phone;

  @include viewport(tablet_p){
  	padding-left: $grid-padding-tablet-p;
  	padding-right: $grid-padding-tablet-p;
  }

  @include viewport(tablet_l){
  	padding-left: $grid-padding-tablet-l;
  	padding-right: $grid-padding-tablet-l;
  }

  @include viewport(desktop){
  	padding-left: $grid-padding-desktop;
  	padding-right: $grid-padding-desktop;
  }

  @include viewport(max_content){
  	padding-left: $grid-padding-max;
  	padding-right: $grid-padding-max;
  }
}

@mixin grid_container(){
	@include full_float();
	display: grid;
  grid-column-gap: $grid-gap-mobile;


	@include viewport(tablet_l){
    grid-column-gap: $grid-gap-desktop;
	}
}
