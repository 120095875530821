//Section Headers

.title{
  padding: 6% 0;
  @include grid_wrapper;
	position: relative;
	float: left;
	width: 100%;
	color: $background-color;
	background: $dark-color;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	text-transform: uppercase;

	.sm{
		font-size: 0.8em;
	}

	> *{
		color: $background-color;
		position: relative;
		z-index: 2;
		opacity: 0;
		animation: fade_in 1s ease forwards;
	}

	> h1:nth-child(2), > div:nth-child(2){
		margin-top: 5px;
		animation-delay: 0.2s;
	}

	> div.breadcrumb{
		padding-left: 0px;
	}

	> div:nth-child(3){
		animation-delay: 0.4s;
	}

	a{
		color: $background-color;
	}

	a:hover{
		color: $primary-color;
	}

	&:before{
		content: '';
		position: absolute;
		top:0px;
		left:0px;
		width: 100%;
		height: 100%;
		background: $title-opac;
	}

	&:after{
		content: '';
		position: absolute;
		top:-5px;
		left:0px;
		width: 100%;
		height: calc(100% + 10px);
		border-top: 1px solid $dark-opac;
		border-bottom: 1px solid $dark-opac;
	}
}

.page-work .title, .page-projects .title{
	background-image: url(../media/images/code.jpg);
}
.page-clients .title{
	background-image: url(../media/images/clients.jpg);
}
.page-about .title, .page-services .title{
	background-image: url(../media/images/comp.jpg);
}
.page-contact .title{
	background-image: url(../media/images/type.jpg);
}
