//Base Colors

$background-color: #faf7f0;
$background-opac: rgba(250, 247, 240, 0.95);
$background-opac-light: rgba(250, 247, 240, 0.75);
$background-opac-lighter: rgba(250, 247, 240, 0.25);

$title-opac: rgba(25, 25, 25, 0.8);

$primary-color: #676566;
//$accent-color: #adaeb0
$accent-color: #707071;
$dark-color: #191919;
$dark-opac: rgba(25, 25, 25, 0.1);
$dark-opac-light: rgba(25, 25, 25, 0.02);
$dark-overlay: rgba(25, 25, 25, 0.7);
$alert-color: #b16464;

$logo-opacity: 0.6;
