.page-home{
  .content{
    display: flex;
    align-items: center;
  }
}

.home{
  @include grid_wrapper;
  background: $background-opac;
  text-transform: uppercase;

  .sm{
		color: $accent-color;
	}

	> div{
		font-weight: 400;
		font-size: 14vw;

    > div:nth-child(1){
      color: $dark-color;
		  font-family: $heading-font;
    }

    > div:nth-child(2){
      font-size: 9vw;
		  font-family: $heading-font;
    }
	}


  @include viewport(tablet_l){

    > div{
      font-size: 145px;
  		line-height: 0.7em;

      > div:nth-child(2){
        font-size: 97px;
      }
    }

  }

}
