//Global Default Styles

body, html{
  margin: 0px;
  padding: 0px;
  height: 100%;
  text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  background: $background-color;
  font-family: $primary-font;
  font-size: $primary-font-size;
  color: $primary-color;
  letter-spacing: $primary-letter-spacing;
}

*, *:before, *:after{
	-webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.mobile_float{
  display: inline;
  width: auto;

  &:after{
    content: ' ';
    white-space: normal;
  }

  @include viewport(tablet_p){
    display: block;
  	width: 100%;
  }
}

.container{
  @include full_float;
}

.content{
  clear: both;
  min-height: calc(100vh - 250px);

  @include viewport(tablet_l){
    min-height: calc(100vh - 175px);
  }

}
