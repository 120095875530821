//Font Variables - @font-face in _fonts.scss
$sans-font: 'Source Sans Pro', sans-serif;

$primary-font: $sans-font;
$primary-font-size: 18px;
$primary-font-weight: 300;
$primary-letter-spacing: 0.05em;

$heading-font: 'league_gothicregular', sans-serif;
$heading-weight: 400;

$med-font-size: 14px;
$small-font-size: 12px;

.no-focus-outline a:focus,
.no-focus-outline button:focus {
  outline: none;
}

a{
	border: none;
	text-decoration: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	color: $primary-color;

	> img{
		outline: none;
		border: none;
	}

	&:hover{
		cursor: pointer;
	}
}

h1, h2, h3, h4{
	text-align: left;
	text-transform: uppercase;
	margin: 0px;
	font-family: $heading-font;
	font-weight: $heading-weight;
	color: $dark-color;
	letter-spacing: 0.05em;
}

h1{
	font-size: 10vw;

	@include viewport(tablet_p){
		font-size: 3em;
	}
}

.sm{
	font-size: 2.5vw;
	line-height: 18px;

	@include viewport(tablet_l){
		font-size: 18px;
	}

}
