button, .btn{
	margin-top: 20px;
	clear: both;
	float: left;
	font-size:25px;
	line-height: 25px;
	width: 100%;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	color: $dark-color;
	font-family: $heading-font;
	text-transform: uppercase;

	> div{
		float: left;
	}

	> div:nth-child(2){
		-ms-flex: 1;
		flex-grow: 1;
		margin-left: 20px;
		position: relative;
	}

	> div:nth-child(2):before{
		content: '\203A';
		float: right;
		margin-right: -1px;
		position: relative;
		z-index: 2;
		font-family: $primary-font;
		line-height: 18px;
	}

	> div:nth-child(2):after{
		content: '';
		width: 100%;
		height: 1px;
		margin-top: -7px;
		float: right;
		background: $dark-color;
	}

	&:hover{
		color: $primary-color;
		cursor: pointer;

		> div:nth-child(2):after{
			background: $dark-color;
		}
	}

}
