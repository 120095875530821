.contact{
  @include grid_wrapper;
  @include grid_container;
  grid-template-columns: repeat(1, 1fr);
  padding-top: 20px;
  padding-bottom: 20px;

  .form{
    @include grid_container;
    grid-template-columns: repeat(1, 1fr);

    .error{
    	float: left;
    	width: 100%;
    	padding: 10px;
    	margin-top: 20px;
    	display: none;

      &.on{
        display: block;
      }
    }

    .error, input.req.err, textarea.req.err, select.req.err{
    	background: $dark-color;
    	color: $background-color;
    }

    > div{
      margin-bottom: 20px;

      > *{
        @include full_float;
      }
    }

    label{
      text-transform: uppercase;
    }

    input, textarea, select{
    	border: 0px;
    	font-family: $primary-font;
    	color: $primary-color;
    	font-size:$primary-font-size;
    	font-weight: $primary-font-weight;
    	letter-spacing: $primary-letter-spacing;
    	text-rendering: optimizeLegibility;
    	-webkit-font-smoothing: antialiased;
    	-moz-osx-font-smoothing: grayscale;
      background: white;
	    padding: 10px;
    }

    .req:before{
      content: '*';
    }

    textarea{
      min-height: 200px;
    }

    .select_wrapper{
  		position: relative;
  		@include full_float;
      background: white;

  		&:after{
  			@include vertical_center();
  			right: 10px;
  			font-family: 'FontAwesome';
  			content: "\f107";
  		  color: $primary-color;
  			z-index: 0;
  			padding-left: 10px;
  			font-size: 1.2em;
  			border-left: 1px solid $primary-color;
  		}

  		&:before{
  			content: '';
  			position: absolute;
  			top:0px;
  			right: 0px;
  			width: 40px;
  			height: 100%;
  			background: #fff;
  		}

  		select{
        @include full_float;
  			position: relative;
  			-webkit-appearance: none;
  		  -moz-appearance: none;
  			background: transparent;
  			border: 0px;
  			margin: 0px;
  			z-index: 1;
        padding: 9px 10px;
  		}
  	}

  }

  .links{
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid $dark-opac;

    p{
      margin-top: 40px;
    }

    a{
      margin-left: 10px;
    }
  }

  @include viewport(tablet_l){
    grid-template-columns: repeat(2, 1fr);

    .form{
      grid-template-columns: repeat(2, 1fr);

      h2, .full_width{
        grid-column: 1 / -1;
      }
    }

    .links{
      margin-top: 0px;
      padding-top: 0px;
      border: none;
      text-align: right;

      *{
        text-align: right;
      }
    }
  }
}
