.paginate{
  @include full_float();
  display: flex;
	border-top: 1px solid $dark-color;


	> a{
		position: relative;
		display: block;
		width: 50%;
		padding: 15px 10px;
		text-align: center;
		color: $background-color;
		background: $dark-color;
		border-bottom: 1px solid $background-opac-lighter;
		font-size: $med-font-size;

		&:hover{
			color: $dark-color;
			background: $background-color;
			*, *:before{
				color: $dark-color;
				background: $background-color;
			}
		}

		&:first-of-type:after{
			content: '';
			@include vertical_center();
			right: 0px;
			width: 1px;
			height: calc(100% - 30px);
			background-color: $background-opac-lighter;
		}

		> div{
			@include full_float();
			position: relative;

			&:before{
				color: $background-opac-light;
				font-size: $small-font-size;
				text-transform: uppercase;
			}

			&.prev{
				&:before{
					content: 'Previous';
				}
			}
			&.next{
				&:before{
					content: 'Next';
				}
			}
		}
	}
}
