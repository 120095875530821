// Global Animated Elements

$animation_margin: 50px;

/********** FADE UP **********/
[data-animate='fade_up']{
  @include animate(1s);
  position: relative;
  opacity: 0;
  top: $animation_margin;

  &.fade_up{
    opacity: 1;
    top: 0;
  }
}

/********** FADE IN **********/
[data-animate='fade_in']{
  @include animate(1s);
  opacity: 0;

  &.fade_in{
    opacity: 1;
  }
}

/********** FADE FROM LEFT **********/
[data-animate='fade_right']{
  @include animate(1s);
  position: relative;
  opacity: 0;
  left: -50%;

  &.fade_right{
    opacity: 1;
    left: 0;
  }
}


@keyframes fade_up{
	from{
		opacity: 0;
		filter: alpha(opacity=0);
		top:50px;
	}
	to{
		opacity: 1;
		filter: alpha(opacity=100);
		top:0px;
	}
}

@keyframes slide_up{
	from{
		top:50px;
	}
	to{
		top:0px;
	}
}

@keyframes fade_in{
	from{
		opacity: 0;
		filter: alpha(opacity=0);
	}
	to{
		opacity: 1;
		filter: alpha(opacity=100);
	}
}
