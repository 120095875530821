//Header Container

header{
  @include grid_wrapper;
  @include grid_container;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  text-align: center;
  grid-template-columns: 1fr;

  .logo{
    max-width: $col-10;
    fill: $dark-color;
  }

  .links{
    font-family: $heading-font;
    font-size: 1.2em;
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    hr{
  		margin: 0 0 5px;
  		border: 0;
  		height: 1px;
  		background: $dark-opac;
  	}

    > li{
      width: 100%;
      margin: 0 5px;
      text-transform: uppercase;
    }

    > li:hover a, > li.active a, > li:hover span, > li.active span{
  		color: $dark-color;
  	}

    > li:hover hr, > li.active hr{
  		background: $dark-color;
  	}

    span{
      display: none;
    }
  }

  @include viewport(tablet_m){
    text-align: right;
    grid-template-columns: 200px auto;
    justify-content: space-between;

    .logo{
      max-width: 100%;
    }

    .links{
      text-align: right;
      font-size: 1.4em;
      padding-top: 0px;

      > li{
        width: auto;
        margin: 0;
        padding: 0 1vw 0 3vw;

        &:last-of-type{
          padding-right: 0px;
        }
      }

      span{
        display: inline;
        font-weight: 300;
    		text-transform: none;
    		font-size: 0.5em;
    		color: $dark-color;
    		font-family: $primary-font;
      }
    }

  }
}
