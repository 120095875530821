.client_list{
  @include grid_wrapper;
  @include grid_container;
  grid-template-columns: repeat(2, 1fr);

  > div{
    padding: 20px;
    overflow: hidden;
  }

  img{
    @include full_float;
		opacity: $logo-opacity;
	}

  @include viewport(phone){
    grid-template-columns: repeat(3, 1fr);
  }

  @include viewport(tablet_p){
    grid-template-columns: repeat(5, 1fr);
  }

  @include viewport(tablet_l){
    grid-template-columns: repeat(7, 1fr);
  }

  @include viewport(desktop){
    grid-template-columns: repeat(10, 1fr);
  }
}
