
.site_list{
  @include grid_wrapper;
  @include grid_container;
  grid-template-columns: repeat(1, 1fr);
  padding-bottom: 20px;

  @include viewport(phone){
    grid-template-columns: repeat(2, 1fr);
  }

  @include viewport(tablet_p){
    grid-template-columns: repeat(3, 1fr);
  }

  @include viewport(desktop){
    grid-template-columns: repeat(4, 1fr);
  }

  > a > div{
		/*border-bottom: 1px solid $dark-opac;
		border-right: 1px solid $dark-opac;*/
		overflow: hidden;
    padding-top: 20px;
    height: 100%;

    > div{
      position: relative;
      overflow: hidden;
      padding-bottom: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 20px;

      > div{
        @include full_float;
        @include full_center;
        border-radius: 20px;
        padding-bottom: 100%;
        background: $dark-overlay;
    		opacity: 0;
    		-webkit-transition: all 0.2s linear;
    		-moz-transition: all 0.2s linear;
    		-o-transition: all 0.2s linear;
    		transition: all 0.2s linear;

        .vert{
          @include full_float;
          @include full_center;
          text-align: center;

          *{
            text-align: center;
            color: $background-color;
          }

        }
      }

      &:hover > div{
        opacity: 1;
      }
    }

	}

}

.site_detail{
  @include grid_wrapper;
  padding-top: 20px;
  padding-bottom: 20px;

  a{
    color: $dark-color;
  }

  > div{
		margin-bottom: 20px;
	}

  img{
    float: left;
    max-width: 100%;
    padding-bottom: 20px;
  }

  .video{
  	position: relative;
  	float: right;
  	margin: 10px 0 20px;
    width: 100%;
    height: 50vw;
  }

  @include viewport(tablet_l){
    text-align: right;

  	h2{
  		text-align: right;
  	}

    img{
      max-width: 50%;
  	  padding-right: 20px;
    }

    .video{
      width: 50%;
    	height: 30vw;
    }
  }
}
